import { makeTeamNavigator, makeTeamSlider } from "./functions/makeSliders";
import updateTeamDetailContent from "./functions/updateTeamDetailContent";

function initializeTeamSliders() {

    // Make team slider
    let teamSlider = makeTeamSlider()

    // Make team navigator
    let teamNavigator = makeTeamNavigator()

    // Do nothing when teamslider and navigator do not exist.
    if(!teamSlider || !teamNavigator) { return; }

    // link team swiper instances
    const teamSwipers = [teamSlider, teamNavigator];

    // Make slides of swiper clickable for navigation
    for (const swiper of teamSwipers) {

        // enable slide click navigation
        $(swiper.$wrapperEl).find('.swiper-slide').each(function (index, slide) {
            const $slide = $(slide);
            $slide.click(function () {
                swiper.slideTo(index);
            });
        });

        swiper.on('slideChange', function (thisSwiper) {
            let activeIndex = thisSwiper.activeIndex;
            for (const otherSwiper of teamSwipers) {
                if (otherSwiper === swiper) {
                    continue;
                }
                otherSwiper.slideTo(activeIndex);
            }
        });
    }

    // update team images, detail content and styling
    teamSlider.on('slideChange', function (swiper) {
        const $wrapper = $(swiper.$wrapperEl);
        const $activeSlide = $(swiper.slides[swiper.activeIndex]);
        const slideData = $activeSlide.data();

        updateTeamDetailContent(slideData);

        $wrapper.find('.profile-picture').removeClass('profile-picture--selected');
        $activeSlide.find('.profile-picture').addClass('profile-picture--selected');
    });

    // update team navigation styling
    teamNavigator.on('slideChange', function (swiper) {
        const $wrapper = $(swiper.$wrapperEl);
        const $activeSlide = $(teamNavigator.slides[swiper.activeIndex]);

        $wrapper.find('.avatar').removeClass('avatar--selected');
        $activeSlide.find('.avatar').addClass('avatar--selected');
    });

    // initialize team detail content
    if(!window.location.hash) {
        let initialSlide = Math.floor(teamSlider.slides.length / 2);
        teamSlider.slideTo(initialSlide);
        teamNavigator.slideTo(initialSlide);
        updateTeamDetailContent($(teamSlider.slides[initialSlide]).data());
    } else {
        teamSlider.emit('slideChange')
    }
}

export default initializeTeamSliders
