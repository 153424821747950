import {HashNavigation, Navigation, Scrollbar, Swiper} from 'swiper';
Swiper.use([Navigation, HashNavigation, Scrollbar]);

let selector = '#contact-team-slider';
let element = $(selector);

let contactTeamSlider = null

if(element.length > 0) {
    contactTeamSlider = new Swiper(selector, {
        slidesPerView: 'auto',
        centeredSlides: true,

        breakpoints: {
            768: {
                centeredSlides: false
            }
        },

        on: {
            slideChange(swiper) {
                const $profilePictures = $(swiper.$wrapperEl).find('.swiper-slide .profile-picture');
                const $activeProfilePicture = $(swiper.slides[swiper.activeIndex]).find('.profile-picture');

                $profilePictures.removeClass('profile-picture--selected');
                $activeProfilePicture.addClass('profile-picture--selected');
            }
        }
    });

    let initialSlide = Math.floor(contactTeamSlider.slides.length / 2);
    contactTeamSlider.slideTo(initialSlide);
}

export default contactTeamSlider;
