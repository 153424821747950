/**
 * Update the team detail content.
 *
 * @param data
 */
function updateTeamDetailContent(data) {

    if (typeof data === 'undefined' || data === null) {
        $('.js-team-detail').hide();
        $('.js-team-no-results').show();
        return;
    } else {
        $('.js-team-no-results').hide();
        $('.js-team-detail').show();
    }

    const socialMediaButtons = [
        {
            selector: '.js-team-detail-facebook-button',
            href: data.facebook
        },
        {
            selector: '.js-team-detail-linkedin-button',
            href: data.linkedin
        }
    ];

    $('.js-team-detail-name').text(data.name);
    $('.js-team-detail-function').text(data.function);
    $('.js-team-detail-description').html(data.description);
    $('.js-team-detail-message-button').attr('href', 'mailto:' + data.email);

    // Hide/show email button
    if (data.email === null || data.email.length === 0) {
        $('.js-team-detail-message-button').hide();
    } else {
        $('.js-team-detail-message-button').show();
    }

    let hasMedia = false;

    for (const buttonData of socialMediaButtons) {
        const $button = $(buttonData.selector);

        if ($button === null) {
            continue;
        }

        if (buttonData.href.length > 0) {
            $button.attr('href', buttonData.href);
            $button.css('display', '');

            hasMedia = true;
        } else {
            $button.attr('href', null);
            $button.css('display', 'none');
        }
    }

    if (hasMedia) {
        $('.js-team-detail-social-label').css('display', '');
    } else {
        $('.js-team-detail-social-label').css('display', 'none');
    }
}

export default updateTeamDetailContent;
