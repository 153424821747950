// Swiper
import {HashNavigation, Navigation, Scrollbar, Swiper} from 'swiper';
Swiper.use([Navigation, HashNavigation, Scrollbar]);

import { throttle } from 'throttle-debounce';

const toPx = require('to-px');

global.Loader = require('@googlemaps/js-api-loader').Loader;

// Modules
import scrollTracker from "./functions/makeScrollTracker";
import initializeTeamSliders from "./modules/team/team";
global.initializeTeamSliders = initializeTeamSliders;
import contactTeamSlider from "./modules/contact";

$(document).ready(function() {
    initializeTeamSliders();
});

// enable top scroll functionality
$(window).scroll(throttle(200, false, function () {
    let $this = $(this);
    let scrollTop = $this.scrollTop();

    $('[data-top-scroll-active-class], [data-top-scroll-inactive-class]').each(function (index, e) {
        let $e = $(e);

        let threshold = toPx($e.attr('data-top-scroll-threshold')) || 32;

        let activeClass = $e.attr('data-top-scroll-active-class');
        let inactiveClass = $e.attr('data-top-scroll-inactive-class');

        if (scrollTop > threshold) {
            if (inactiveClass !== null) {
                $e.removeClass(inactiveClass);
            }

            if (activeClass !== null) {
                $e.addClass(activeClass);
            }
        } else {
            if (activeClass !== null) {
                $e.removeClass(activeClass);
            }

            if (inactiveClass !== null) {
                $e.addClass(inactiveClass);
            }
        }
    });
}));
