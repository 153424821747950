const scrollTracker = new window.ScrollTracker('js-scroll-tracker', {
    background: '#377acf',
    height: '5px',
    events: {
        beforeDraw: () => {
        },
        afterDraw: () => {
        },
        beforeCalculate: () => {
        },
        afterCalculate: () => {
        }
    }
});

export default scrollTracker
