import {HashNavigation, Navigation, Scrollbar, Swiper} from 'swiper';
Swiper.use([Navigation, HashNavigation, Scrollbar]);

/**
 * Makes instance of a team slider
 */
function makeTeamSlider() {

    let elementSelector = '#js-team-slider';
    let $element = $(elementSelector);

    if($element.length < 1) {
        return;
    }

    return new Swiper(elementSelector, {
        centeredSlides: true,

        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },

        hashNavigation: {
            watchState: true,
            replaceState: true
        },

        breakpoints: {
            768: {
                direction: 'vertical',
                slidesPerView: 3
            }
        },
    });
}

/**
 * Makes instance of a team navigator
 */
function makeTeamNavigator() {

    let elementSelector = '#js-team-navigator-slider';
    let $element = $(elementSelector);

    if($element.length < 1) {
        return;
    }

    return new Swiper(elementSelector, {
        slidesPerView: 'auto',
        centeredSlides: true,

        navigation: {
            nextEl: '#js-team-next-button',
            prevEl: '#js-team-prev-button'
        },

        scrollbar: {
            el: '.swiper-scrollbar',
            hide: false,
            draggable: true,
        },

        breakpoints: {
            768: {
                spaceBetween: 30
            }
        }
    });
}

export { makeTeamSlider, makeTeamNavigator }
